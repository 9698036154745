import React, { createRef, useState } from "react";
import dayjs from "dayjs";
import AgilityImage from "./../../components/AgilityImage";
import SharePage from "./../../components/SharePage";
import s from "./ArticleDetails.module.css";
import ArticleAuthor from "../../components/ArticleAuthor";

const Gallery = ({ media, galleryID, description, count }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [inFocus, setInFocus] = useState(0);

  const cycle = (num = 1) => {
    let target = inFocus + num;
    if (target < count && target > -1) {
    } else if (target >= count) {
      target = target - count;
    } else if (target <= -1) {
      target = count + target;
    }

    setInFocus(target);
    console.log(target, parseInt(count));
  };
  return (
    <>
      <div className="grid grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-3 ">
        {media.map((item, idx) => (
          <AgilityImage
            key={idx}
            onClick={(e) => {
              setIsOpen(true);
              setInFocus(idx);
            }}
            imageClassName="hover:opacity-75 "
            className="cursor-pointer hover:bg-orange"
            {...item}
          />
        ))}
      </div>
      {isOpen && (
        <>
          <div className="fixed flex justify-center items-center w-screen h-screen bg-black top-0 bg-opacity-50 z-50 left-0">
            <button
              className="fixed bg-white hover:bg-orange hover:text-white w-8 h-8 rounded-full flex justify-center items-center content-center"
              style={{
                top: `20px`,
                right: `20px`,
                fontSize: `16px`,
                lineHeight: `16px`,
              }}
              onClick={(e) => {
                e.preventDefault();
                setIsOpen(false);
              }}
            >
              ×
            </button>
            <button
              className="bg-white hover:bg-orange hover:text-white w-12 text-center uppercase text-sm px-3 py-2 flex justify-center items-center content-center mx-4"
              style={{
                top: `20px`,
                right: `20px`,
                fontSize: `16px`,
                lineHeight: `16px`,
              }}
              onClick={(e) => {
                e.preventDefault();
                cycle(-1);
              }}
            >
              prev
            </button>
            <figure
              className="z-50 mx-auto block"
              style={{
                height: `calc(100vh - 50px)`,
                maxHeight: `600px`,
                top: `25px`,
              }}
            >
              <img
                className={`${s.galleryImage} block w-full h-full object-contain mx-auto`}
                src={media[inFocus].url}
                alt={media[inFocus]?.metaData?.Title}
              />
              {!!media[inFocus]?.metaData?.Title && (
                <figcaption className="w-full bg-white px-3 pt-2 pb-3 text-sm">
                  <strong>{media[inFocus]?.metaData?.Title}</strong>{" "}
                  {media[inFocus]?.metaData?.Description}
                </figcaption>
              )}
            </figure>
            <button
              className=" bg-white hover:bg-orange hover:text-white w-12 text-center uppercase text-sm px-3 py-2 flex justify-center items-center content-center mx-4"
              style={{
                top: `20px`,
                right: `20px`,
                fontSize: `16px`,
                lineHeight: `16px`,
              }}
              onClick={(e) => {
                e.preventDefault();
                cycle(1);
              }}
            >
              next
            </button>
          </div>
        </>
      )}
    </>
  );
};

const ArticleDetails = (props) => {
  // return false;
  const { currentPage, categories = [] } = props;
  if (!!!currentPage && !!!currentPage.fields) {
    return false;
  }
  const { thisDynamicItem } = currentPage;

  if (!!!thisDynamicItem) {
    return false;
  }

  let _cats = [];

  if (!!categories.length) {
    _cats = categories.map((cat) => cat);
  }
  const {
    title,
    textblob,
    date,
    authorName,
    authorNames,
    articleReferences = "",
    authors,
    imageGallery,
    listingImage,
    featuredImage,
    journalName = "Appl Radiol",
    pDFUrl,
    type,
    issue,
    volume,
    year,
    pageNumber,
    categoriesIDs,
    subcategoriesIDs = [],
    modified,
    articleTitle,
    sponsor,
  } = thisDynamicItem.fields;
  const articleDate = dayjs(date);
  const publishDate = dayjs(modified);

  const isNews = subcategoriesIDs.indexOf("3032") > -1;

  console.log(thisDynamicItem.fields);

  return (
    <article className="px-guttersm lg:px-gutterlg">
      <script
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `
        googletag.cmd.push(function() {googletag.pubads().setTargeting("sponsor", "${sponsor}");});
        console.log('sponsor', "${sponsor}")
        `,
        }}
      ></script>
      <header className="flex justify-between items-center">
        <div className="block">
          <div className="text-sm inline-block font-bold text-grey">
            {_cats.map((c) => {
              return (
                <a
                  href={`/Articles?searchSubCategories=${c?.contentID}`}
                  className="text-orange mr-2 hover:text-blue"
                >
                  {c?.fields?.title}
                </a>
              );
            })}
          </div>
          <time
            className="text-sm inline-block text-grey mb-1 ml-6"
            dateTime={articleDate.format("YYYY-MM-DD")}
          >
            {isNews
              ? publishDate.format("MMM DD, YYYY")
              : articleDate.format("MMM DD, YYYY")}
          </time>
        </div>
        <SharePage
          contentID={thisDynamicItem.contentID}
          {...thisDynamicItem.fields}
        />
      </header>
      <h1 className="text-3xl font-bold font-serif leading-none mb-3 text-blue">
        {articleTitle || title}
      </h1>
      {!!authorNames && (
        <p className="mt-sm mb-4 text-sm text-grey">
          By{" "}
          {!!authors && !!authors.contentID ? (
            <a href={`/Author-Bios/?authorID=${authors.contentID}`}>
              {authorNames}
            </a>
          ) : (
            authorNames
          )}
        </p>
      )}
      {/* <div className="DOI mb-4 text-blue">
        {!isNews && (
          <a
            target="_blank"
            href={`https://crossref.org/10.37549/AR${thisDynamicItem.contentID}`}
            className="text-sm"
          >
            https://crossref.org/10.37549/AR{thisDynamicItem.contentID}
          </a>
        )}
      </div> */}
      {!!imageGallery && !!imageGallery?.count ? (
        <Gallery {...imageGallery} />
      ) : (
        <AgilityImage {...featuredImage} />
      )}
      <div className="typeset" dangerouslySetInnerHTML={{ __html: textblob }} />
      {!!articleReferences && (
        <div
          className="typeset"
          dangerouslySetInnerHTML={{
            __html: `
        <h2>References</h2>
        <ol>
        <li>
        ${articleReferences.replace(/\r?\n|\r|\n/g, "</li><li>")}
        </li>
        </ol>
      `,
          }}
        ></div>
      )}
      {!!authorNames && !!articleDate && (
        <div
          className="bg-orangeLight p-8 mt-6"
          style={
            {
              // backgroundColor: ``,
            }
          }
        >
          {authorNames}. ({articleDate.format("MMM DD, YYYY")}). {title}.{" "}
          <em>Appl Radiol.</em> {year}; {volume}({issue}):{pageNumber}.
          {!!authors && !!authors.fields && !!authors.fields.textblob && (
            <div
              className="mt-sm mb-0 text-sm text-grey"
              dangerouslySetInnerHTML={{
                __html: `${authors.fields.textblob}`,
              }}
            />
          )}
          {/* {!isNews && <a
            className="text-orange"
            target="_blank"
            href="https://doi.org/10.0000/0000"
          >
            https://doi.org/10.0000/0000
          </a>} */}
        </div>
      )}
    </article>
  );
};

export default ArticleDetails;
